import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useWalletsService from '../../../controllers/wallets/service';
import { iconMap } from '../../../theme/Icons';
import { RiskLvl } from '../../../types/transaction';
import { Button } from '../../atoms/Button';
import ResponsiveIcon from '../../atoms/Icon';
import { Progress } from '../../atoms/Progress';
import {
	DisplayMode,
	DisplayModeSwitcher,
} from '../../molecules/DisplayModeSwitcher';
import WalletsGrid from '../../organisms/WalletsGrid';
import WalletsList from '../../organisms/WalletsList';
import { cn } from '../../../libs/cn';
import { NewWalletPopUp } from '../../molecules/NewWalletPopUp';
import DeleteWalletModal from '../../specialized/dialogs/DeleteWallet';
import { EditWalletTitlePopup } from '../../molecules/EditWalletTitlePopup';
import { Wallet } from '../../../types/wallets';
import { EmptyPlaceholder } from '../../atoms/EmptyPlaceholder';

export const riskLevelColorMap = {
	[RiskLvl.critical]: 'bg-critical-100',
	[RiskLvl.medium]: 'bg-medium-100',
	[RiskLvl.high]: 'bg-high-100',
	[RiskLvl.low]: 'bg-low-100',
};

const WalletsPage: React.FC = () => {
	const navigate = useNavigate();

	const { data, getWallets, loading } = useWalletsService();
	const [selectedMode, setSelectedMode] = useState<DisplayMode>(
		DisplayMode.grid
	);
	const [activePopupName, setActivePopupName] = useState<
		'newWallet' | 'editWalletName' | 'deleteWallet' | null
	>(null);
	const [activeWallet, setActiveWallet] = useState<Wallet | null>(null);

	useEffect(() => {
		getWallets();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeDisplayMode = (mode: DisplayMode) => {
		setSelectedMode(mode);
	};

	const goToWalletDetails = (id: string) => {
		navigate(`/wallets/${id}`);
	};

	if (loading.wallets && !data.wallets?.length) {
		return (
			<div className="flex justify-center items-center min-h-[600px]">
				<Progress />
			</div>
		);
	}

	return (
		<>
			<div className="flex flex-col gap-8">
				{!data?.wallets.length && (
					<EmptyPlaceholder className="flex-1 min-h-[450px]">
						<EmptyPlaceholder.Icon
							icon={iconMap.noTransactionShadow}
							size={100}
						/>
						<EmptyPlaceholder.Description description="You haven't added any wallet yet" />
						<Button
							iconPosition="right"
							icon={
								<ResponsiveIcon icon={iconMap.plusIcon} className="h-5 w-5" />
							}
							onClick={() => {
								setActivePopupName('newWallet');
								setActiveWallet(null);
							}}
						>
							Add New Wallet
						</Button>
					</EmptyPlaceholder>
				)}
				{!!data?.wallets.length && (
					<div className="flex gap-4 2xl:gap-8 items-center flex-row justify-between">
						<div className="flex flex-row items-center">
							<DisplayModeSwitcher
								modes={[DisplayMode.grid, DisplayMode.list]}
								selectedMode={selectedMode}
								onChangeDisplayMode={handleChangeDisplayMode}
								icons={{
									grid: iconMap.tileMode,
									list: iconMap.listMode,
								}}
								title={'View'}
							/>
							<span className="text-sm text-grey-600">
								{!!data?.wallets?.length && data.wallets.length > 0 && (
									<>
										{data.wallets.length} Wallet{data.wallets.length > 1 && 's'}{' '}
										active
									</>
								)}
							</span>
						</div>
						<Button
							iconPosition="right"
							icon={
								<ResponsiveIcon icon={iconMap.plusIcon} className="h-5 w-5" />
							}
							onClick={() => {
								setActivePopupName('newWallet');
								setActiveWallet(null);
							}}
						>
							New Wallet
						</Button>
					</div>
				)}
				<WalletsList
					data={data.wallets}
					goToWalletDetails={goToWalletDetails}
					className={cn({
						hidden: selectedMode !== DisplayMode.list,
					})}
					onDeleteClick={(wallet) => {
						setActiveWallet(wallet);
						setActivePopupName('deleteWallet');
					}}
					onEditTitleClick={(wallet) => {
						setActiveWallet(wallet);
						setActivePopupName('editWalletName');
					}}
				/>
				<WalletsGrid
					data={data.wallets}
					className={cn({
						hidden: selectedMode !== DisplayMode.grid,
					})}
					goToWalletDetails={goToWalletDetails}
					onDeleteClick={(wallet) => {
						setActiveWallet(wallet);
						setActivePopupName('deleteWallet');
					}}
					onEditTitleClick={(wallet) => {
						setActiveWallet(wallet);
						setActivePopupName('editWalletName');
					}}
				/>
				{activePopupName === 'newWallet' && (
					<NewWalletPopUp
						handleClose={() => setActivePopupName(null)}
						onSuccess={() => {
							getWallets();
							setActivePopupName(null);
						}}
					/>
				)}
				{activePopupName === 'editWalletName' && activeWallet && (
					<EditWalletTitlePopup
						currentName={activeWallet?.name}
						address={activeWallet?.address}
						handleClose={() => setActivePopupName(null)}
						onSuccess={() => {
							getWallets();
							setActivePopupName(null);
						}}
					/>
				)}
				{activePopupName === 'deleteWallet' && activeWallet && (
					<DeleteWalletModal
						isOpen
						walletId={activeWallet.id}
						onDelete={() => {
							getWallets();
							setActiveWallet(null);
						}}
						handleClose={() => setActiveWallet(null)}
					/>
				)}
			</div>
		</>
	);
};

export default WalletsPage;
