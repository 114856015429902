import { useNavigate } from 'react-router-dom';
import { cn } from '../../../libs/cn';
import { iconMap } from '../../../theme/Icons';
import {
	CRYPTOCURRENCY,
	cryptocurrencySignMap,
	CURRENCY,
	currencySignMap,
} from '../../../types/currency';
import { Transaction, TransactionStatus } from '../../../types/transaction';
import { formatNumber } from '../../../utils/number';
import { buildExplorerLink, shortenString } from '../../../utils/string';
import { Badge, BadgeProps } from '../../atoms/Badge';
import { Button } from '../../atoms/Button';
import { Card, CardContent, CardHeader, CardTitle } from '../../atoms/Card';
import { Guarantee } from '../../atoms/Guarantee';
import ResponsiveIcon from '../../atoms/Icon';
import { Tooltip } from '../../atoms/Tooltip';
import { WalletCodeCell } from '../../organisms/Table/baseConfig';
import { BaseTable } from '../../organisms/Table/BaseTable';
import { Row, TableCell, TableRow } from '../../organisms/Table/Table';
import { ColumnType } from './columns';

export const RenderExpandedRowContent: React.FC<{ row: Row<Transaction> }> = ({
	row,
}) => {
	const total = row.original?.totalAmount;

	return (
		<div
			className={cn(
				`bg-grey-200 gap-24 transition-all absolute bottom-4 right-4 left-4 top-20 rounded-lg overflow-hidden`,
				row.getIsExpanded() ? 'flex' : 'hidden'
			)}
		>
			<div className="flex flex-col gap-3 pt-4 pl-4">
				<p className="font-bold text-lg">Fees</p>
				<div className="flex gap-6">
					<div className="flex flex-col gap-3">
						<p>Transaction Fees</p>
						<p>Money Back Guarantee Fees</p>
					</div>

					<div className="flex flex-col gap-3">
						<div className="flex gap-2 items-baseline">
							<p className="flex gap-1">
								<span className="font-extrabold">
									{cryptocurrencySignMap[total?.from.unit as CRYPTOCURRENCY]}
								</span>
								<span>{formatNumber(total?.from.value)}</span>
							</p>

							<p className="flex gap-1 text-grey-600 text-sm">
								<span className="font-extrabold">
									{currencySignMap[total?.to.unit as CURRENCY]}
								</span>
								<span>{formatNumber(total?.to.value)}</span>
							</p>
						</div>

						<div className="flex gap-2 items-baseline">
							<p className="flex gap-1">
								<span className="font-extrabold">
									{cryptocurrencySignMap[total?.from.unit as CRYPTOCURRENCY]}
								</span>
								<span>{formatNumber(total?.from.value)}</span>
							</p>

							<p className="flex gap-1 text-grey-600 text-sm">
								<span className="font-extrabold">
									{currencySignMap[total?.to.unit as CURRENCY]}
								</span>
								<span>{formatNumber(total?.to.value)}</span>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-3 items-start pt-4">
				<p className="font-bold text-lg">Money Back Guarantee</p>
				<Button variant={'outline'}>Make a Claim</Button>
			</div>
		</div>
	);
};

type TransactionTableProps = {
	title?: string;
	subtitle?: string;
	onClick?: () => void;
	btnText?: string;
	isLoading: boolean;
	error: string | null;
	data: Transaction[];
	columns: ColumnType[];
	getRowProps?: (row: any) => React.HTMLAttributes<HTMLTableRowElement>;
	type: 'simple' | 'extended';
};

export function TransactionTable({
	columns,
	data,
	onClick,
	title,
	subtitle,
	btnText,
	isLoading,
	error,
	type,
}: TransactionTableProps) {
	const navigate = useNavigate();
	const getRowProps = (row: any) => {
		return {
			className: `group ${row.original.status === TransactionStatus.transferring ? 'hover-opacity-row' : ''}`,
		};
	};

	if (type === 'simple') {
		return (
			<Card className="flex flex-col gap-4 min-h-[500px]">
				<CardHeader className="items-center gap-2 justify-between flex-col sm:flex-row">
					<div>
						{title && (
							<CardTitle className="font-normal font-roboto-condensed text-xl">
								{title}
							</CardTitle>
						)}
						{subtitle && <p>{subtitle}</p>}
					</div>

					{onClick && btnText && (
						<Button onClick={onClick} variant={'outline'} size={'sm'}>
							{btnText}
						</Button>
					)}
				</CardHeader>
				{/* {data.length === 0 && !error && (
					<EmptyPlaceholder className="flex-1">
						<EmptyPlaceholder.Icon icon={iconMap.magnifyWalletWithShadow} />
						<EmptyPlaceholder.Description description="You haven't made any transactions yet" />
					</EmptyPlaceholder>
				)} */}

				<CardContent>
					<BaseTable
						columns={columns}
						data={data.slice(0, 5)}
						isLoading={isLoading}
						error={error}
						getRowProps={getRowProps}
						headerStyle="bg-grey-50"
					/>
				</CardContent>
			</Card>
		);
	} else {
		return (
			<BaseTable
				columns={columns}
				data={data}
				isLoading={isLoading}
				error={error}
				getRowProps={getRowProps}
				emptyPlaceHolderContent={
					<Button className="mt-8" onClick={() => navigate('/send')}>
						Make Any Transaction
						<ResponsiveIcon icon={iconMap.transactions} className="h-8 w-8" />
					</Button>
				}
				renderRowSubComponent={({ row }) => {
					if (
						row?.original?.outputs &&
						row.original.outputs.length > 1 &&
						row.getIsExpanded()
					) {
						return (
							<>
								<TableRow>
									<TableCell
										colSpan={row.getVisibleCells().length}
										className="space-y-1"
									>
										{row.original.outputs.map((output, index) => {
											const hashLink = buildExplorerLink(
												output.hash,
												row.original.chainId?.toLowerCase()
											);

											const riskFactorCount = output.risk_factors?.length || 0;

											return (
												<div
													key={index}
													className="grid grid-cols-6 border border-grey-150 rounded-md p-2.5 text-grey-600 text-sm"
												>
													<div>
														<Tooltip content={output.address}>
															<WalletCodeCell wallet={output.address} />
														</Tooltip>
													</div>
													<div>
														<Tooltip
															content={output.amount}
															className="cursor-pointer"
														>
															<span className="text-sm text-black">
																{output.amount}
															</span>
														</Tooltip>
													</div>
													<div>
														<a
															href={hashLink}
															rel="noreferrer"
															target="_blank"
															className={cn({
																underline: !!output.hash,
																'pointer-events-none': !output.hash,
															})}
														>
															{output.hash?.length
																? shortenString(output.hash)
																: '-'}
														</a>
													</div>
													<div className="flex flex-row align-baseline">
														<Tooltip
															content={
																riskFactorCount
																	? output.risk_factors?.join(',')
																	: null
															}
														>
															<span className="pl-2 truncate flex items-center justify-center">
																{`${riskFactorCount} factor${riskFactorCount > 1 ? 's' : ''}`}
															</span>
														</Tooltip>
													</div>
													<div>
														<Badge
															variant={
																output.risk_score?.toLowerCase() as BadgeProps['variant']
															}
															className="capitalize w-[81px] justify-center"
														>
															{output.risk_score}
														</Badge>
													</div>
													<div>
														<Guarantee
															variant={output.approved ? 'yes' : 'no'}
														/>
													</div>
												</div>
											);
										})}
									</TableCell>
								</TableRow>
							</>
						);
					}

					return null;
				}}
			/>
		);
	}
}
