import { CellContext, ColumnDef } from '@tanstack/react-table';

import { cn } from '../../../libs/cn';
import { iconMap } from '../../../theme/Icons';
import { Transaction, WalletDataType } from '../../../types/transaction';
import { buildExplorerLink, shortenString } from '../../../utils/string';
import ResponsiveIcon from '../../atoms/Icon';
import {
	getDateTimeCell,
	getRiskLevelAndFactorsCell,
	getTotalAmountCell,
	WalletCodeCell,
} from '../../organisms/Table/baseConfig';
import { Tooltip } from '../../atoms/Tooltip';

const getTypeCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();

	return (
		<div className="flex gap-2 items-center">
			{value === 'in' && (
				<ResponsiveIcon
					icon={iconMap.plus}
					colors={['#1CE7DC', '#1CE7AC', '#1CE7A2']}
					className="w-3.5 h-3.5"
				/>
			)}

			{value === 'out' && (
				<ResponsiveIcon
					icon={iconMap.minus}
					colors={['#E15136', '#EC4B2E', '#F04121']}
					className="w-3.5 h-2"
				/>
			)}
			<span className="capitalize">{value}</span>
		</div>
	);
};

export const historyColumns: (ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: any;
})[] = [
	{
		accessorKey: 'dateTime',
		header: 'Sent',
		cell: getDateTimeCell,
		size: 130,
		enableSorting: true,
	},
	{
		accessorKey: 'from',
		header: 'From',
		cell: (props) => {
			const wallet = props.getValue<WalletDataType>();
			return (
				<Tooltip content={wallet.code || wallet.wallet}>
					<WalletCodeCell
						wallet={wallet.code || wallet.wallet}
						className={cn({
							truncate: !props.row.getIsExpanded(),
							'break-words': props.row.getIsExpanded(),
						})}
					/>
				</Tooltip>
			);
		},
		size: 20,
		enableSorting: true,
	},
	{
		accessorKey: 'to.code',
		header: 'To',
		cell: (props) => {
			const code = props.getValue<string>();
			return (
				<Tooltip content={code}>
					<WalletCodeCell
						wallet={code}
						className={cn({
							truncate: !props.row.getIsExpanded(),
							'break-words': props.row.getIsExpanded(),
						})}
					/>
				</Tooltip>
			);
		},
		size: 80,
		enableSorting: true,
	},
	{
		accessorKey: 'type',
		header: 'Type',
		cell: getTypeCell,
		size: 80,
		enableSorting: true,
	},
	{
		accessorKey: 'hash',
		header: 'Hash#',
		cell: (props) => {
			const value = props.getValue<string>();
			// const coinName = props.row.original.coinName?.toLowerCase();
			const chainId = props.row.original.chainId?.toLowerCase();
			const hashLink = buildExplorerLink(value, chainId);
			return (
				<a
					href={hashLink}
					rel="noreferrer"
					target="_blank"
					className="underline"
				>
					{value.length ? shortenString(value) : '-'}
				</a>
			);
		},
		enableSorting: true,
		size: 100,
	},
	{
		accessorKey: 'totalAmount',
		header: () => <span>Total Volume</span>,
		cell: getTotalAmountCell,
		enableSorting: true,
		size: 100,
	},
	{
		accessorKey: 'riskLevel',
		header: () => <span className="block truncate">Risk Level & Factors</span>,
		cell: getRiskLevelAndFactorsCell,
		size: 100,
	},
	// {
	// 	accessorKey: 'riskLevel',
	// 	header: () => <span>Risk Level & Factors</span>,
	// 	cell: ({ row }) => {
	// 		const riskLevel = (row.original as any).riskLevel;
	// 		const riskFactor = (row.original as any).riskFactor;
	// 		return (
	// 			<div className="flex flex-row align-baseline">
	// 				<Badge
	// 					variant={riskLevel as BadgeProps['variant']}
	// 					className="capitalize w-[81px] justify-center"
	// 				>
	// 					{riskLevel}
	// 				</Badge>
	// 				{riskFactor !== undefined && (
	// 					<span className="pl-2 truncate flex items-center justify-center">
	// 						{riskFactor} Factor{riskFactor > 1 ? 's' : ''}{' '}
	// 					</span>
	// 				)}
	// 			</div>
	// 		);
	// 	},
	// 	enableSorting: true,
	// 	size: 100,
	// },
	{
		accessorKey: 'riskFactor',
		header: '',
		cell: () => {},
		size: 0,
	},
	{
		accessorKey: 'coinSymbol',
		header: '',
		cell: () => {},
		size: 0,
	},
	// {
	// 	accessorKey: 'riskFactor',
	// 	header: () => <span className="whitespace-nowrap">Risk Factor</span>,
	// 	cell: (props) => {
	// 		const value = props.getValue<string>();
	// 		return <span>{value}</span>;
	// 	},
	// },
	// 	size: 120,
	// 	enableSorting: true,
	// },
	// {
	// 	accessorKey: 'status',
	// 	header: () => <span className="whitespace-nowrap">Status</span>,
	// 	cell: (props) => {
	// 		const value = props.getValue<string>();
	// 		return <span>{value}</span>;
	// 	},
	// 	size: 120,
	// 	enableSorting: true,
	// },
	// {
	// 	accessorKey: 'useMbg',
	// 	header: () => <div className="flex-1 truncate">Money Back Guarantee</div>,
	// 	cell: getGuaranteeCell,
	// 	size: 120,
	// },
	// {
	// 	accessorKey: 'useMbg',
	// 	header: () => <span>Money Back Guarantee</span>,
	// 	cell: (props) => <div>{props.getValue<boolean>() ? 'Yes' : 'No'}</div>,
	// 	size: 130,
	// },
];
