import {
	useAppKit,
	useAppKitAccount,
	useDisconnect,
} from '@reown/appkit/react';
import React, { useEffect, useState } from 'react';
import { parseEther } from 'viem';
import { useSendTransaction } from 'wagmi';
import useSendTransactionsService from '../../../controllers/transactions/service';
import { SendTransactionWithResponse } from '../../../controllers/transactions/slice';
import { useToast } from '../../../hooks/useToast';
import { cn } from '../../../libs/cn';
import { iconMap } from '../../../theme/Icons';
import { RiskLvl } from '../../../types/transaction';
import { formatNumberWithPrefix } from '../../../utils/number';
import { Badge } from '../../atoms/Badge';
import { Button } from '../../atoms/Button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '../../atoms/Dialog';
import ResponsiveIcon from '../../atoms/Icon';
import { TransactionTime } from '../QuickTransaction';

const TransactionSummaryDialog: React.FC<{
	isOpen?: boolean;
	chain?: string;
	transactions?: SendTransactionWithResponse[];
	handleClose?: () => void;
	onComplete?: (transactionId: string) => void;
}> = ({ chain, transactions, isOpen, handleClose, onComplete }) => {
	const { warning } = useToast();

	const { data, fetchMarketDataPrice } = useSendTransactionsService();

	const [loadingIds, setLoadingIds] = useState<string[]>([]);

	const { open } = useAppKit();
	const { isConnected } = useAppKitAccount();
	const { disconnect } = useDisconnect();
	const { sendTransactionAsync } = useSendTransaction();

	useEffect(() => {
		if (chain?.length) {
			fetchMarketDataPrice(chain, 'USD');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chain]);

	const isNonBTC = chain?.toUpperCase() !== 'BTC';

	const isNonBtcAndDisconnected = isNonBTC && !isConnected;

	const handleBitcoinTransaction = (
		recipient: string,
		currencyAmount: string
	) => {
		try {
			const bitcoinUrl = `bitcoin:${recipient}?amount=${currencyAmount}`;
			window.location.href = bitcoinUrl;
			return true;
		} catch (error) {
			console.error(error);
			window.open('https://bitcoin.org/en/choose-your-wallet', '_blank');
			return false;
		}
	};

	const handleWalletConnect = async (
		recipient: string,
		currencyAmount: string
	) => {
		try {
			await sendTransactionAsync({
				to: recipient as any,
				value: parseEther(currencyAmount),
			});
			await disconnect();
			return true;
		} catch (error) {
			console.error('WalletConnect error:', error);
			return false;
		}
	};

	const handleConnectTransaction = async (
		transaction: SendTransactionWithResponse
	) => {
		try {
			setLoadingIds((loadingIds) => [...loadingIds, transaction.id]);

			if (!chain || !transaction) {
				return;
			}

			if (isNonBtcAndDisconnected) {
				await open();
				return;
			}

			let isSuccessful = false;
			if (chain.toUpperCase() === 'BTC') {
				isSuccessful = handleBitcoinTransaction(
					transaction.response.input_address,
					transaction.response.total_amount
				);
			} else {
				isSuccessful = await handleWalletConnect(
					transaction.response.input_address,
					transaction.response.total_amount.toString()
				);
			}
			if (isSuccessful) {
				onComplete && onComplete(transaction.id);

				if (transactions?.length === 1) {
					handleClose && handleClose();
				}
			}
		} catch (error: any) {
			warning('Failed to send transaction: ' + error);
		} finally {
			setLoadingIds(loadingIds.filter((id) => id !== transaction.id));
		}
	};

	const icon = iconMap[chain?.toLowerCase() as keyof typeof iconMap];
	const usdRate = data.marketDataPrice?.usd || 0;

	return (
		<Dialog open={isOpen}>
			<DialogContent className="sm:max-w-[600px] py-12 max-h-lvh" hideCloseBtn>
				<DialogHeader>
					<DialogTitle className="flex flex-col sm:flex-row gap-2 sm:gap-5 items-center text-2xl font-bold text-center border-b border-[#A7D0C7] pb-3">
						<ResponsiveIcon
							icon={iconMap.transactions}
							color={'var(--color-primary-300)'}
							size={32}
						/>
						<p>Transaction Summary</p>
					</DialogTitle>
				</DialogHeader>

				<DialogDescription className="flex flex-col gap-y-8 max-h-[50vh] overflow-y-auto">
					{transactions?.map((transaction, index) => {
						const fee = Number(transaction?.response.fee || 0);
						const riskLevel =
							transaction?.response.outputs?.[0]?.risk_score?.toLowerCase() as RiskLvl;

						return (
							<div
								key={`${index}-${transaction.response.transaction_id}`}
								className="space-y-2"
							>
								<div className="grid grid-cols-2 gap-2">
									<div className="space-y-2 flex-1">
										<div className="text-md font-bold">Total Fees</div>
										<div className="flex gap-2 items-center">
											<ResponsiveIcon icon={iconMap.hourglass} />
											<div className="flex gap-2 items-center">
												<div className="flex gap-1 items-center">
													{icon && (
														<ResponsiveIcon
															icon={icon}
															className="h-[18px] stroke-white"
														/>
													)}
													{!icon && !!chain && <>{chain}</>}
													<span>{formatNumberWithPrefix(fee)}</span>
												</div>
												<div className="flex gap-1 items-center">
													<ResponsiveIcon
														icon={iconMap.dollar}
														className="h-[18px]"
													/>
													<span>{formatNumberWithPrefix(fee * usdRate)}</span>
												</div>
											</div>
										</div>
									</div>
									{riskLevel && (
										<div className="space-y-2 flex-1">
											<div className="text-md font-bold">Wallet Risk Level</div>
											<div className="flex gap-2 items-center">
												<Badge variant={riskLevel}>{riskLevel}</Badge>
											</div>
										</div>
									)}
									<div className="space-y-2 flex-1">
										<div className="text-md font-bold">
											Money Back Guarantee
										</div>
										<div className="flex gap-2 items-center">
											{transaction?.response.mbg_policy ? 'Yes' : 'No'}
										</div>
									</div>
									<div className="space-y-2 flex-1 !text-black">
										<TransactionTime
											titleClassName="text-md font-bold mb-2"
											containerClassName=" !text-black"
											progressBarType="default"
											transactionId={transaction.response.transaction_id}
										/>
									</div>
								</div>
								<Button
									className="text-sm"
									onClick={() => handleConnectTransaction(transaction)}
									disabled={loadingIds.includes(
										transaction.response.transaction_id
									)}
								>
									{isNonBtcAndDisconnected
										? 'Connect Wallet'
										: isNonBTC
											? 'Send Transaction'
											: 'Open Bitcoin App'}{' '}
									<ResponsiveIcon
										size={5}
										className={cn('animate-spin', {
											hidden: !loadingIds?.includes(
												transaction.response.transaction_id
											),
										})}
										icon={iconMap.spinner}
									/>
								</Button>
							</div>
						);
					})}
				</DialogDescription>
				<DialogFooter className="mt-4">
					<Button
						className="text-lg"
						variant="outline"
						disabled={!!loadingIds.length}
						onClick={handleClose}
					>
						Cancel
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default TransactionSummaryDialog;
