import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '../../../atoms/Form';
import { Button } from '../../../atoms/Button';
import { Input } from '../../../atoms/Input';
import useAuthService from '../../../../controllers/auth/service';

type ValueNames = 'code1' | 'code2' | 'code3' | 'code4' | 'code5' | 'code6';

export const formSchema = z.object({
	code1: z.string().min(1).max(1),
	code2: z.string().min(1).max(1),
	code3: z.string().min(1).max(1),
	code4: z.string().min(1).max(1),
	code5: z.string().min(1).max(1),
	code6: z.string().min(1).max(1),
});

interface VerifyCodeFormProps {
	onVerifyCodeSuccess: (values: z.infer<typeof formSchema>) => void; // Callback function when sign-in is successful
}

const VerifyCodeForm: React.FC<VerifyCodeFormProps> = ({
	onVerifyCodeSuccess,
}) => {
	const { error, clearError, loading, sendMfa, data } = useAuthService();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		mode: 'onChange',
		defaultValues: {
			code1: '',
			code2: '',
			code3: '',
			code4: '',
			code5: '',
			code6: '',
		},
	});

	const {
		setValue,
		formState: { isSubmitting, isValid, errors, defaultValues },
		trigger,
	} = form;

	function handleResendMfa(e?: React.MouseEvent) {
		e?.preventDefault();
		e?.stopPropagation();

		if (data.mfaToken && data.selectedMfaOption && !loading.resendMfa) {
			sendMfa(data.mfaToken, data.selectedMfaOption);
		}
	}
	const handlePaste = useCallback(
		(event: any) => {
			let data = event.clipboardData.getData('text');

			data = data.split('');

			[].forEach.call(
				document.querySelectorAll('.field-code'),
				(node: any, index) => {
					node.value = data[index];
					const fieldIndex = `code${index + 1}`;
					setValue(fieldIndex as ValueNames, data[index]);
				}
			);

			event.preventDefault();
			trigger();
		},
		[setValue, trigger]
	);

	useEffect(() => {
		const target = document.querySelector('input.field-code');

		target?.addEventListener('paste', handlePaste);

		return () => {
			target?.removeEventListener('paste', handlePaste);
		};
	}, [handlePaste]);

	const handleChangeWithNextField = (
		event: React.ChangeEvent<HTMLInputElement>,
		handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	) => {
		const { maxLength, value, name } = event.target;

		const fieldIndex = name.replace('code', '');
		const fieldIntIndex = Number(fieldIndex);

		if (value.length >= maxLength) {
			if (fieldIntIndex < 6) {
				const nextField = document.querySelector(
					`input[name=code${fieldIntIndex + 1}]`
				);

				if (nextField !== null) {
					(nextField as HTMLElement).focus();
				}
			}
		}

		handleChange(event);
	};

	const handleVerifyCode = (
		event: React.ChangeEvent<HTMLInputElement>,
		field: any
	) => {
		handleChangeWithNextField(event, field.onChange);
		clearError();
	};

	const handleSubmit = () => {
		if (isValid && !isSubmitting) {
			form.handleSubmit(onVerifyCodeSuccess)();
		}
	};

	return (
		<Form {...form}>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
				className="flex flex-col gap-8 h-full 3xl:gap-14 justify-between"
			>
				<div className="flex flex-col gap-8 3xl:gap-y-14">
					<div className="flex flex-col gap-4 relative">
						<div className="space-y-1">
							<div className="flex gap-x-2 sm:gap-x-2.5">
								{defaultValues &&
									Object.keys(defaultValues)?.map((name, index) => {
										return (
											<FormField
												key={name}
												control={form.control}
												name={`code${index + 1}` as ValueNames}
												render={({ field }) => (
													<FormItem>
														<FormControl>
															<Input
																className="field-code h-10 sm:h-14 px-1 sm:px-2 rounded-sm bg-white text-center border-white"
																maxLength={1}
																{...field}
																autoFocus={index === 0}
																autoComplete="off"
																onChange={(ev) => handleVerifyCode(ev, field)}
																onKeyDown={(e) => {
																	if (e.key === 'Enter') {
																		e.preventDefault();
																		if (isValid) {
																			handleSubmit();
																		}
																	}
																}}
															/>
														</FormControl>
													</FormItem>
												)}
											/>
										);
									})}
							</div>

							{error.verifyLogin && (
								<FormMessage className="text-right">
									{error.verifyLogin}
								</FormMessage>
							)}
						</div>

						{(!!errors.code1 ||
							!!errors.code2 ||
							!!errors.code3 ||
							!!errors.code4 ||
							!!errors.code5 ||
							!!errors.code6) && (
							<p className="text-xs font-medium text-red-400 absolute bottom-[-20px] right-0">
								Code is invalid
							</p>
						)}
					</div>

					<div className="flex gap-1">
						<span>Didn&apos;t get a code?</span>
						<Button
							onClick={handleResendMfa}
							variant={'link'}
							className="font-bold text-black p-0"
							loading={loading.resendMfa}
						>
							Click to resend
						</Button>
						.
					</div>
				</div>

				<div className="mr-0 ml-auto">
					<Button
						disabled={!isValid || isSubmitting}
						type="button"
						size={'lg'}
						onClick={handleSubmit}
						className="bg-white"
						loading={loading.verifyLogin}
					>
						Log In
					</Button>
				</div>
			</form>
		</Form>
	);
};

export default VerifyCodeForm;
