import { RiskLvl, Transaction, WalletDataType } from '../types/transaction';
import { RecursivePartial } from '../types/util';
import { ResponseWallet, Wallet } from '../types/wallets';
import { ResponseWalletTransaction } from './../types/wallets';

export const walletResponseMapper = (
	wallet: ResponseWallet
): RecursivePartial<Wallet> => {
	return {
		id: wallet.wallet_id,
		name: wallet.wallet_name,
		address: wallet.wallet_id,
		cryptocurrencies: [
			{
				code: wallet.wallet_chain,
				name: wallet.wallet_chain,
				amount: 32,
				canSend: true,
			},
		],
		riskLevel: wallet.summary.risk_score as RiskLvl,
		riskFactors: wallet.summary.risk_factors,
		canSend: false,
		chainId: wallet.wallet_chain,
	};
};

// const getRiskDetails = (walletTransaction: ResponseWalletTransaction) => {
// 	const partyType =
// 		walletTransaction.transaction_type === 'out' ? 'receiver' : 'sender';

// 	return {
// 		riskLevel: walletTransaction[`${partyType}_risk_score`],
// 		riskFactor: walletTransaction[`${partyType}_risk_factors`],
// 	};
// };

export const walletTransactionsResponseMapper = (
	walletAddress: string,
	walletTransaction: ResponseWalletTransaction
): RecursivePartial<Transaction> => {
	const date = new Date(walletTransaction.created_at);
	const dateTime = date.toLocaleString();
	const from: WalletDataType =
		walletTransaction.direction === 'out'
			? {
					code: '',
					wallet: `${walletAddress}`,
					walletId: Number(walletAddress),
				}
			: {
					code: '',
					wallet: `${walletTransaction.counterparty}`,
					walletId: Number(walletAddress),
				};

	const to =
		walletTransaction.direction === 'in'
			? {
					code: `${walletAddress}`,
					wallet: '',
					walletId: Number(walletAddress),
				}
			: {
					code: `${walletTransaction.counterparty}`,
					wallet: '',
					walletId: Number(walletAddress),
				};
	return {
		id: walletTransaction.unique_id,
		dateTime,
		from,
		proxy: '',
		to,
		type: walletTransaction.direction,
		hash: walletTransaction.tx_hash,
		totalAmount: {
			from: {
				value: walletTransaction.amount,
				unit: walletTransaction.coin_name,
			},
			to: {
				value: walletTransaction.usd_value,
				unit: walletTransaction.coin_name,
			},
			fee: walletTransaction.usd_value.toString(),
		},
		riskLevel: walletTransaction.risk_score,
		riskFactor: walletTransaction.risk_factors,
		status: '',
		moneyBackGuarantee: 'Yes',
		coinName: walletTransaction.coin_name,
		coinSymbol: walletTransaction.coin_symbol,
		direction: walletTransaction.direction,
		counterparty: walletTransaction.counterparty,
	};
};
