import { Navigate } from 'react-router-dom';

import useAuthService from '../controllers/auth/service';
import { useIdleTimeout } from '../hooks/useIdleTimeout';
import { getCurrentUser } from '../controllers/auth/api';
import { usePromise } from '../hooks/usePromise';

const AuthAndVerificationGuard = ({ children }: any) => {
	const {
		data: { isAuthenticated, isVerified },
		logout,
		loading,
	} = useAuthService();

	const checkTokenRequest = usePromise({
		promiseFunction: async () => {
			try {
				await getCurrentUser();
			} catch {
				logout();
			}
		},
	});

	useIdleTimeout({
		timeout: 10 * 60 * 1000, // 10 minutes
		onTimeout: () => {
			if (!checkTokenRequest.pending) {
				checkTokenRequest.call();
			}
		},
		isActive: isAuthenticated && isVerified,
	});

	if (loading.refreshToken) {
		return;
	}

	if (!isAuthenticated) {
		return <Navigate to="/auth/login" replace />;
	}

	if (!isVerified) {
		return <Navigate to="/auth/verify-code" replace />;
	}

	return children;
};

export default AuthAndVerificationGuard;
