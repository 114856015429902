import { useEffect, useState, useCallback } from 'react';

interface UseIdleTimeoutProps {
	timeout: number;
	onTimeout: () => void;
	isActive?: boolean;
	events?: string[];
}

export const useIdleTimeout = ({
	timeout,
	onTimeout,
	isActive = true,
	events = [
		'mousedown',
		'mousemove',
		'keydown',
		'scroll',
		'touchstart',
		'click',
	],
}: UseIdleTimeoutProps) => {
	const [isIdle, setIsIdle] = useState(false);
	const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

	const timeoutCallback = useCallback(() => {
		setIsIdle(true);
		onTimeout();
	}, [onTimeout]);

	function resetTimer() {
		if (timer) {
			clearTimeout(timer);
			setTimer(null);
		}

		if (isActive) {
			const newTimer = setTimeout(timeoutCallback, timeout);
			setTimer(newTimer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	function handleActivity() {
		setIsIdle(false);
		resetTimer();
	}

	useEffect(() => {
		if (!isActive) {
			if (timer) {
				clearTimeout(timer);
				setTimer(null);
			}
			setIsIdle(false);
			return;
		}

		resetTimer();

		const boundHandleActivity = handleActivity;
		events.forEach((event) => {
			window.addEventListener(event, boundHandleActivity);
		});

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
			events.forEach((event) => {
				window.removeEventListener(event, boundHandleActivity);
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isActive]);

	return { isIdle };
};
