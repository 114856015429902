import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export function parseDateTime(
	dateTimeString: string
): { date: string; time: string } | null {
	const dateObject = new Date(dateTimeString);

	if (isNaN(dateObject.getTime())) {
		return null;
	}

	const day = String(dateObject.getUTCDate()).padStart(2, '0');
	const month = dateObject.toLocaleString('en-US', { month: 'short' });
	const year = dateObject.getUTCFullYear();

	const date = `${day} ${month} ${year}`;

	const hours = String(dateObject.getUTCHours()).padStart(2, '0');
	const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');

	const time = `${hours}:${minutes}`;

	return {
		date: date,
		time: time,
	};
}
